/* eslint-disable @typescript-eslint/no-var-requires */
import i18next from 'i18next'
// import Backend from 'i18next-http-backend'

i18next
  .init({
    lng: 'pt-BR',
  })
  .catch(console.error)
  .then(async () => {
    const { Application } = await import('ohA/applications')
    const { App } = await import(':/core/app')

    Application.render(App)
  })
  .catch(console.error)
